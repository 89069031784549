import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Autocomplete, Box, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Snackbar } from '@mui/material';
import api, {get_one} from '../../../services/api';
import { render } from 'react-dom';
import { ConstructionOutlined } from '@mui/icons-material';
import theme from '../../../themeContent';
import { ThemeProvider } from '@emotion/react';
import { useAuth } from '../../../components/AuthProvider';
import { useFormError } from '../../../components/Datatable/FormErrorProvider';
import CustomTextField from '../../../components/CustomTextField';
import { useNotify } from '../../../components/Datatable/NotifyProvider';




export default function FormDialog(props) {
  const [handlerSnack, setHandlerSnack] = React.useState()
  const auth = useAuth()
  const item = {
    email: '',
    name: '',
    username: "",
    password: "",
    new_password: "",
    confirm_new_password: "",

  }
  const [changePassword, setChangePassword] = React.useState(false)
  const [state, setState] = React.useState(item);
  const error = useFormError()
  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };
  

  React.useEffect(() => {
    if (!props.open) return
    getEditInfo()
    setChangePassword(false)
    error.setErrors([])

  }, [ props.open])

  const getEditInfo = () => {
    setState(item)
    api.get('admin/user/me/')
      .then(res => {
        setState(res.data)
      }).catch(err => setState(item))

  }
  const notify = useNotify()
  const [msgSnack, setMsgSnack] = React.useState('')
  const [sevSnack, setSevSnack] = React.useState('')
 

 

  const handleSubmit = (event) => {
    event.preventDefault()
    if(changePassword && state.new_password !== state.confirm_new_password){
      notify.setNotify({
        open: true,
        message: "As senhas não coincidem.",
        severity: 'error',
      })
      return
    }
    if (state?.new_password?.length < 8 && state?.new_password?.length > 0) {
      notify.setNotify({
        open: true,
        message: "A senha deve ter no mínimo 8 caracteres.",
        severity: 'error',
      })
      return
    }

    const handlerClose = () => {
      props.handlerClose()
    }
   
      api.put('admin/user/profile/', {...state})
      .then((res) => {
        if (res?.data?.errors) {
          error.setErrors(res.data.errors)
          return
      }
          notify.setNotify({
            open: true,
            message: "Perfil atualizado com sucesso.",
            severity: 'success',
          })
          handlerClose()
        }).catch((err) => {
          console.log(err)
          notify.setNotify({
            open: true,
            message: "Erro ao atualizar perfil.",
            severity: 'error',
          })
        })
    
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={props.open} fullWidth={true} scroll={'paper'}
        maxWidth='sm'>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <DialogTitle>
            Editar Perfil
          </DialogTitle>
          <DialogContent>

            <CustomTextField
              error={error.verify('name')}
              helperText={error.msgError('name')}
              size="small"
              required
              margin="dense"
              id="name"
              name="name"
              label="Nome"
              type="name"
              fullWidth
              value={state.name}
              onChange={handleChange}
              variant="outlined"
            />
            <CustomTextField
              disabled
              size="small"
              margin="dense"
              id="username"
              name="username"
              label="Nome do usuário"
              type="username"
              fullWidth
              value={state.username}
              onChange={handleChange}
              variant="outlined"
            />
            <CustomTextField
              error={error.verify('email')}
              helperText={error.msgError('email')}
              size="small"
              margin="dense"
              id="email"
              name="email"
              label="email"
              type="email"
              fullWidth
              value={state.email}
              onChange={handleChange}
              variant="outlined"
            />
            <CustomTextField
              error={error.verify('password')}
              helperText={error.msgError('password')}
              size="small"
              margin="dense"
              id="password"
              name="password"
              label={changePassword? "Senha Antiga":"Confirmar Senha"}
              type="password"
              minLength="8"
              fullWidth
              value={state.password}
              onChange={handleChange}
              variant="outlined"
            />
            {
              changePassword &&
              <>
              <CustomTextField
              error={error.verify('new_password')}
              helperText={error.msgError('new_password')}
              size="small"
              margin="dense"
              id="new_password"
              name="new_password"
              label="Nova Senha"
              type="password"
              minLength="8"
              fullWidth
              value={state.new_password}
              onChange={handleChange}
              variant="outlined"
              />
            <CustomTextField
            error={error.verify('confirm_new_password')}
            helperText={error.msgError('confirm_new_password')}
            size="small"
            margin="dense"
            id="confirm_new_password"
            name="confirm_new_password"
            label="Confirmar Nova Senha"
            type="password"
            minLength="8"
            fullWidth
            value={state.confirm_new_password}
            onChange={handleChange}
            variant="outlined"
            />
            </>
          }
           {!changePassword && 
          <Button sx={{ color: theme.palette.text.primary }} onClick={()=>setChangePassword(true)}>Alterar Senha</Button>
        } 
          </DialogContent>
          <DialogActions>
            <Button sx={{ color: theme.palette.text.primary }} onClick={props.handlerClose}>Fechar</Button>
            <Button sx={{ color: theme.palette.text.primary }} onClick={handleSubmit}> Salvar</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
}

