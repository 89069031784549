import { Box, Button, Collapse, Divider, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Paper, styled, TextField, ThemeProvider, Tooltip, Typography } from '@mui/material'
import React, { act, useCallback, useEffect, useState } from 'react'
import theme from '../../../themeContent'
import RowRadioButtonsGroup from '../../../components/RowRadiusButtonsGroup'
import api, { create, get_one } from '../../../services/api'
import { Select } from '@mui/material'
import { ChangeCircle, Edit, ExpandCircleDown, RemoveCircle, } from '@mui/icons-material'
import { red } from '@mui/material/colors'
import { Form, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useFormError } from '../../../components/Datatable/FormErrorProvider'
import { useNotify } from '../../../components/Datatable/NotifyProvider'
import CustomTextField from '../../../components/CustomTextField';

const ProductInterface = {
    product: null,
    services: [],
    productPrice: 0,
    actvationValue: 0,
    quantity: 1,
    total: 0,
    total_raw: 0,
    isExpanded: true
}
const LeadInfo = ({ id, handler }) => {
    const [lead, setLead] = useState({})
    const navigate = useNavigate()
    const [description, setDescription] = useState('')
    const error = useFormError()
    useEffect(() => {

        get_one('lead', id).then(res => {
            if (res.data.error) {
                navigate('/lead')
                return
            }
            setLead(res.data.data)

        }).catch(e =>
            navigate('/leads')

        )
    }, [id])
    return (
        <Box 
        display={"flex"} flexDirection={"column"}
        >
            <CustomTextField 
            label="Nome do Projeto" 
            value={lead.des}
            error={error.verify('description')}
            helperText={error.msgError('description')}

            
            onChange={e => {
                setDescription(e.target.value)
                handler(e.target.value)
            }}
            sx={{
                width: "100%",
                mb: 1
            }} />
            <Typography variant="h7">Empresa: {lead.company_name}</Typography>
            <Typography variant="h7">CNPJ: {lead.cnpj}</Typography>
        </Box>
    )

}

export default function Budget() {

    const navigate = useNavigate()
   
    const { lead } = useParams()
    const error = useFormError()
    const notify = useNotify()

    const [state, setState] = useState({
        products: [],
        total: {
            total: 0,
            activation_value: 0,
            discount: 0,
            obs: ''
        },
        location: 0,
        discountMax: 0
    })
    const { products, location, discountMax, total } = state
    const setDiscountMax = (value) => setState({ ...state, discountMax: value })
    const setProducts = (value) => setState({ ...state, products: value })
    const setLocation = (value) => setState({ ...state, location: value })
    const setTotal = (value) => setState({ ...state, total: value })

    useEffect(() => {
        //if change location, reset products
        setProducts([])
    }, [location])
    //Get discount max
    useEffect(() => {
        api.get('budget/empresa/max_discount').then(res => {
            setDiscountMax(res.data.max_discount)

        })
    }, [])
 

    useEffect(() => {
        const total_temp = products.reduce((acc, curr) => {
            const subtotalServices = curr.services.reduce((acc, curr) => acc + Number(curr.price) * Number(curr.value), 0)
            return {
                total: Number(acc.total) + Number(curr.productPrice + subtotalServices) * Number(curr.quantity),
                activation_value: Number(acc.activation_value) + Number(curr.actvationValue) * Number(curr.quantity)
            }
        }, { total: 0, activation_value: 0 })


        setTotal({
            ...total, total_raw: total_temp.total, total: total_temp.total - (total_temp.total * total.discount / 100),
            activation_value: total_temp.activation_value

        })
    }, [products, total.discount])
    return (
        <ThemeProvider theme={theme}>
            <Box display={'flex'} flexDirection={'column'} flexWrap={'wrap'} rowGap={3} columnGap={3} >

                <Paper elevation={2}  >
                    <Box p={1} m={1} display={"flex"} flexDirection={"column"}  >
                        <Box>
                            <LeadInfo id={lead} handler={v=> setState({...state,description: v})}/>
                        </Box>
                    </Box>
                    <Box display={"flex"} flexDirection={"row"} flexWrap={"nowrap"} justifyContent={"space-between"} >

                        <LocationOptions handler={value => setLocation(value)} />
                        <Button

                            disabled={location == 0}
                            variant={"outlined"} onClick={() => {
                                //check if has option withou product
                                
                                if (products.find(p => !p.product)) {
                                    notify.setNotify({ open: true, message: 'Selecione um produto para continuar.', severity: 'error' })
                                    return
                                }

                                const tempProduct = ProductInterface
                                const max_id = products.length == 0 ? 0 : Math.max(...products.map(p => p.id))
                                tempProduct.id = max_id + 1
                                setProducts([...products, tempProduct])
                            }}
                            sx={{ m: 1, maxHeight: 40, minWidth: 200 }}
                        >Adicionar produto</Button>
                    </Box>
                    <Box>

                    </Box>
                    {products.map((product, index) =>
                        <ProductForm key={product.id + "prod"} data={product}
                            location={location}
                            handler={value => {
                                const data = { ...value, id: product.id }
                                setProducts([...products.filter(p => p.id != data.id), data])
                            }}
                            handlerRemove={() => {
                                const confirm = window.confirm('Deseja remover este item?')
                                if (confirm) {
                                    if (products.length == 1) {
                                        setProducts([])
                                        return
                                    }
                                   setProducts(products.filter((p, i) =>product.id != p.id))
                                }
                            }}
                        />  
                    )}
                    <Divider />

                    <>
                        <Box m={2} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} >

                            <FormControl sx={{ mr: 2, }} fullWidth>

                                <TextField label="Observações" maxRows={10}
                                    multiline
                                    rows={4}
                                    value={total.obs}
                                    onChange={e => setTotal({ ...total, obs: e.target.value })}
                                />
                            </FormControl>


                            <Box
                                minWidth={200}
                            >

                                <Typography variant={"h6"}>Total: {total.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                <Typography variant="h8" sx={{ mt: 1, ml: 1, color: red[500] }}> Desconto: {(total.total_raw - total.total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}({total.discount}%)</Typography>
                                <Typography variant={"h6"}>Ativação: {total.activation_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                            </Box>
                        </Box>
                        <Divider />
                    </>

                    <Box m={2} display={"flex"} flexDirection={"row"} justifyContent={"end"}
                    >
                        <FormControl sx={{ width: 200, mr: 2 }}
                        >
                            <TextField label="Desconto"
                                disabled={products.find(p => !p.product || p.services.length == 0) || products.length == 0}
                                inputProps={{
                                    min: 0,
                                    max: discountMax,
                                    maxLength: 6,
                                    type: 'number',

                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                                }}
                                value={total.discount}
                                onChange={e => {
                                    if (e.target.value > discountMax) {
                                        e.target.value = discountMax
                                    }
                                    setTotal({ ...total, discount: e.target.value })
                                }} />
                        </FormControl>
                        <Button variant={"contained"}
                            disabled={products.find(p => !p.product || p.services.length == 0) || products.length == 0}
                            onClick={() => {
                                create('budget/create', { products: products, lead: lead, location, discount: total.discount, obs: total.obs, description: state.description }).then(res => {
                                    if (res.data.error) {
                                        notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                                        return
                                    }
                                    if (res.data.errors) {
                                        error.setErrors(res.data.errors)
                                        return
                                    }

                                    notify.setNotify({ open: true, message: 'Recurso criado com sucesso.', severity: 'success' })
                                    navigate('/lead')
                                    error.setErrors([])
                                })
                                    .catch(e =>
                                        notify.setNotify({ open: true, message: e.message, severity: 'error' }))


                            }}>Salvar</Button>
                    </Box>
                </Paper>

            </Box>

        </ThemeProvider>
    )
}




function LocationOptions({ handler = () => { } }) {
    const [locations, setLocations] = useState([])
    const [location, setLocation] = useState(0)
    const [showOptions, setShowOptions] = useState(true)
    useEffect(() => {
        api.get('budget/locations/all/').then(res => {
            setLocations(res.data)
        })
    }, [])
    return (
        <Box p={1} m={1}>

            {!location ?
                <Typography variant="h6" ml={1}>Localização: </Typography>
                : <Box display={"flex"} alignItems={"center"}>
                    <Typography variant="h6" ml={1}>Localização: {locations.find(l => l.id == location)?.description}</Typography>
                    <Tooltip title="Alterar Localização">
                        <IconButton onClick={() => { setShowOptions(true); setLocation(0); handler(0) }} >
                            <Edit />
                        </IconButton>
                    </Tooltip>
                </Box>
            }
            {showOptions &&
                <RowRadioButtonsGroup
                    handleChange={(id) => { handler(id); setLocation(id); setShowOptions(false) }}
                    options={
                        locations?.map(p => ({ label: p.description, value: p.id }))

                    }
                    value={location}
                />
            }
        </Box>
    )

}




export function ProductForm({ handler = () => { },location=0, handlerRemove = () => { }, data = {
    product: null,
    services: [],
    productPrice: 0,
    actvationValue: 0,
    quantity: 1,
    total: 0,
    isExpanded: true
} }) {
    const [payload, setPayload] = useState(data)
    const [total, setTotal] = useState({
        value: 0,
        actvationValue: 0,
    })


    useEffect(() => {

        if (payload.services) {
            const subtotalServices = payload.services.reduce((acc, curr) => acc + curr.price * curr.value, 0)

            setTotal(subtotalServices)
            handler(payload)

        }


    }, [payload])
    return (
        <>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} pl={3}>

            </Box>
            <Divider />
            <Box m={2} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Box>

                    <ProductOptions
                        location={location}
                        handler={(value) =>

                            setPayload({ ...payload, productPrice: Number(value.value), actvationValue: Number(value.actvationValue), product: value.id })

                        }
                    >
                        {payload.product &&
                            <>
                                <Typography variant="h8" sx={{ mt: 1, ml: 1 }}> Preço:{((Number(payload.productPrice) + Number(total))).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                <Typography variant="h8" sx={{ mt: 1, ml: 1 }}> Ativação:{payload?.actvationValue?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                            </>

                        }
                    </ProductOptions>
                </Box>
                <FormControl sx={{
                    width: 200, mb: 1,
                    display: !payload.product ? 'none' : 'block'

                }}>
                    {/* <TextField label="Quantidade"
                        key={payload.id + "quantity"}
                        inputProps={{
                            min: 1, style: { textAlign: 'center' },
                            type: 'number'
                        }}
                        sx={{
                            width: 100, mt: 1, ml: 1,

                        }}

                        value={payload?.quantity}
                        onChange={e => {
                            //only numbers above 1
                            const value = e.target.value.replace(/\D/g, '')

                            setPayload({ ...payload, quantity: Number(value) })
                        }}
                    /> */}
                </FormControl>
                {payload.product &&
                    <>
                        <Typography variant="h6" align='left' sx={{ mt: 1, ml: 1, minWidth: 200 }}>{((Number(payload.productPrice) + Number(total)) * payload.quantity).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                        <Box display={"flex"} flexDirection={"column"} >
                            {payload.services
                                .sort((a, b) => {
                                    if (a.type_service === b.type_service) {
                                        return a.description > b.description ? 1 : -1;
                                    }
                                    return a.type_service > b.type_service ? 1 : -1;
                                })
                                .map(service => {


                                    switch (service.type) {
                                        case "radio":
                                            return <Typography variant="h8" key={service.id + "radio"} sx={{ mt: 1, ml: 1 }}> {service.type_service}: {service.description} {service.unit == service.description ? "" : service.unit} </Typography>
                                            break
                                        case "select":
                                            return <Typography variant="h8" key={service.id + "select"} sx={{ mt: 1, ml: 1 }}>{service.description}:  {service.value} {service.unit == service.description ? "" : service.unit}</Typography>
                                            break
                                        case "number":
                                            return <Typography variant="h8" key={service.id + "number"} sx={{ mt: 1, ml: 1 }}>{service.description}: {service.value} {service.unit == service.description ? "" : service.unit}</Typography>
                                            break
                                        default:
                                            <Typography variant="h8" key={service.id + "radio"} sx={{ mt: 1, ml: 1 }}>{service.description} : {service.value} {service.unit == service.description ? "" : service.unit} </Typography>
                                    }

                                })}

                        </Box>

                        <Box display={"flex"} alignItems={"center"}>

                            <IconButton onClick={handlerRemove} >
                                <RemoveCircle sx={{ color: red[900] }} />
                            </IconButton>

                            <ExpandMore
                                expand={payload.isExpanded}
                                onClick={() => setPayload({ ...payload, isExpanded: !payload.isExpanded })}
                            >
                                <ExpandCircleDown sx={{ color: red[400] }} />
                            </ExpandMore>
                        </Box>
                    </>
                }
            </Box>

            <Collapse in={payload.isExpanded} timeout={"auto"}>



                <ServiceOptions
                    payload={payload.services}
                    product={payload?.product}
                    handler={(value) => setPayload({ ...payload, services: value })}
                />

            </Collapse >

        </>
    )
}

function ProductOptions({ handler = () => { },location=0, children }) {
    const [products, setProducts] = useState([])
    const [isSelect, setIsSelect] = useState(false)
    useEffect(() => {
        if (!location) return
        api.get('budget/products/'+location).then(res => {
            setProducts(res.data)
        })
    }, [location])
    if (products.length == 0) {
        return ""
    }

    return (

        <Box p={1} m={1}>
            {!isSelect ?
                <>
                    <Typography variant="h6" ml={1}>Selecione o Produto:</Typography>
                    <RowRadioButtonsGroup
                        handleChange={(id) => {
                            const prod = products.find(p => p.id == id)
                            handler({ value: prod.value, actvationValue: prod.activation_value, id: prod.id })
                            setIsSelect(prod.description)
                        }}
                        options={
                            products.map(p => ({ label: p.description, value: p.id }))

                        }
                    />
                </>
                : <Box display={'flex'} flexDirection={"column"}>
                    <Typography variant='h6'> {isSelect}</Typography>
                    {children}
                </Box>
            }
        </Box>
    )
}

function ServiceOptions({ payload, handler = () => { }, product }) {
    const [services, setServices] = useState([])
    const [types, setTypes] = useState([])
    const [firstRender, setFirstRender] = useState(true)
    useEffect(() => {
        if (!product) return
        api.get(`budget/services2/${product}/all/`).then(res => {
            setServices(res.data)
            setTypes([...new Set(res.data.map(p => p.type_service))])
        })



    }, [product])

    useEffect(() => {
        if (services.length == 0) return
        if (firstRender) {
            setFirstRender(false)
            handler(
                services.filter(s => s.min_quantity > 0 && s.type_service != "Localização")
                    .sort((a, b) => {
                        if (a.type_service === b.type_service) {
                            return a.description > b.description ? 1 : -1;
                        }
                        return a.type_service > b.type_service ? 1 : -1;
                    })
                    .map(s => (
                        {
                            value: s.min_quantity,
                            price: s.value,
                            id: s.id,
                            type_service: s.type_service,
                            description: s.description,
                            unit: s.unit,
                            type: "number"
                        }
                    )
                    ))
        }
    }, [services])

    return (
        types.map(type => (
            (
                !services.find(s => s.type_service === type).multiple && services.filter(s => s.type_service === type).length > 1)
                || type == "Localização" ||
                (!services.find(s => s.type_service === type).min_quantity == 0 && services.filter(s => s.type_service === type).length == 1
                    && !services.find(s => s.type_service === type).options && services.find(s => s.type_service === type).multiple
                )
                ?
                <Box p={1} m={1} key={type + "BOX"}>
                    <Typography variant="h6" ml={1}>{type}:</Typography>
                    <RowRadioButtonsGroup key={type + "RowBtnOptions"}
                        handleChange={v => handler([...payload.filter(s => s.type_service != type),
                            , {
                            value: 1,
                            price: services.find(s => s.id == v).value,
                            id: v,
                            type_service: type,
                            description: services.find(s => s.id == v).description,
                            unit: services.find(s => s.id == v).unit,
                            type: "radio"
                        }

                        ])}
                        options={
                            services.filter(s => s.type_service == type).map(p => ({ label: p.description, value: p.id }))
                        }>
                    </RowRadioButtonsGroup>
                </Box>
                :
                <Box p={1} m={1} key={type + "BOX"}>
                    <Typography variant="h6" ml={1}>{type}:</Typography>
                    {services.filter(s => s.type_service === type).map(service =>
                        <Box ml={1} display={"flex"} flexWrap={'wrap'} flexDirection={'row'}
                            key={service.id + "SUBBOX"}
                        >


                            {service.options && service.type_number ==0 ? <BaseSelect label={service.description} key={service.id + "SELECT"}
                                options={
                                    service.options.split(',').map(o => ({ label: o + " " + service.unit, value: o }))
                                }
                                value={service.options.split(',')[0]}
                                handler={v => handler([...payload.filter(i => i.id !== service.id)
                                    , {
                                    value: v, price: service.value,
                                    id: service.id,
                                    type_service: type,
                                    description: service.description,
                                    unit: service.unit,
                                    type: "select"

                                }])}
                                suffix={service.unit} />
                                :
                                <TextField label={service.description}
                                    key={service.id + "TEXTFIELD"}
                                    sx={{ width: 200, mb: 1 }}
                                    inputProps={{
                                        min: service.min_quantity || 0,
                                        max: service.max_quantity,

                                        type: 'number'
                                    }}
                                    onChange={e => {
                                        //only numbers
                                        let value = e.target.value.replace(/\D/g, '')
                                        if (value > service.max_quantity) {
                                            value = service.max_quantity
                                        }
                                        handler([...payload.filter(i => i.id !== service.id),
                                        {
                                            value: value,
                                            price: service.value,
                                            id: service.id,
                                            type_service: type,
                                            description: service.description,
                                            unit: service.unit,
                                            type: "number"

                                        }])
                                    }}
                                    value={payload?.find(i => i?.id === service?.id)?.value}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{service.unit}</InputAdornment>,
                                    }}

                                />
                            }

                        </Box>
                    )}
                </Box>
        ))
    )



}

function BaseSelect({ options, handler, label = 'Selecione', suffix = '', value }) {
    const [_value, setValue] = useState(value)
    return (

        <FormControl sx={{ width: 200, mb: 1 }}>
            <InputLabel id={"demo-simple-select-label" + label}>{label}</InputLabel>
            <Select
                labelId={"demo-simple-select-label" + label}
                id={"demo-simple-select" + label}
                //if options get first value
                value={_value}
                label={label}
                onChange={e => {
                    handler(e.target.value)
                    setValue(e.target.value)
                }}
            >
                {options.map(option => <MenuItem key={option.value + label} value={option.value}>{option.label}</MenuItem>)}
            </Select>
        </FormControl>
    )
}


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));