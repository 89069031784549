import * as React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ItemMenu, { ListItemMenu } from '../../components/ItemMenu';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import {List } from '@mui/material';
import { useAuth } from '../../components/AuthProvider';
import BusinessIcon from '@mui/icons-material/Business';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Profile from '../../pages/client/profile';

export const MainListItems = () => {

  const auth = useAuth();

  return (
    <List>
      <ItemMenu icon={<DashboardIcon />}
        name="Dashboard" path="/" perm={""} />
      <ItemMenu icon={<BusinessIcon />} name="Leads" path="/lead" perm="lead.get_all" />
      <ItemMenu icon={<ListAltIcon />} name="Orçamentos" path="/budget" perm="budget.get_all" />
      <ItemMenu icon={<Profile />}  perm={false}/>
      {/* <ListItemMenu icon={<SettingsIcon />} name={"Config"}>
        <ItemMenu icon={<GroupIcon />} name="Cargos" path="/role" perm="role.get_all" />
        <ItemMenu icon={<PersonIcon />} name="Usuários" path="/user" perm="user.get_all" />
      </ListItemMenu> */}
    </List>
  )
};


export const secondaryListItems = (
  <React.Fragment>

  </React.Fragment>
);
