import * as React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ItemMenu, { ListItemMenu } from '../../components/ItemMenu';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import BusinessIcon from '@mui/icons-material/Business';
import { List } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CategoryIcon from '@mui/icons-material/Category';
import InventoryIcon from '@mui/icons-material/Inventory';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import { ListAlt } from '@mui/icons-material';
import Profile from '../../pages/admin/profile';


export const MainListItems =({open})=>{
  
  return (
    <List>
  
        {/* <ItemMenu icon={<DashboardIcon />} admin={true}
          name="Dashboard" path="/admin" perm="user.get_all"/> */}
      <ItemMenu icon={<BusinessIcon />} name="Empresas" path="/admin/empresa" perm="empresa.get_all" admin />
      <ItemMenu icon={<BusinessIcon />} name="Leads" path="/admin/lead" perm="lead.get_all" admin/>
      <ItemMenu icon={<ListAlt />} name="Orçamentos" path="/admin/budget" perm="budget.get_all" admin/>
      <ListItemMenu icon={<PersonIcon />} name={"Produtos"} >
        <ItemMenu icon={<LocationOnIcon />} name="Localização" path="/admin/product/location" perm="location.get_all" admin />
        <ItemMenu icon={<CategoryIcon />} name="Tipo de Produto" path="/admin/product/product_type" perm="product_type.get_all" admin />
        <ItemMenu icon={<InventoryIcon />} name="Produto" path="/admin/product" perm="product.get_all" admin />
        <ItemMenu icon={<CategoryIcon />} name="Tipo de Recurso" path="/admin/product/service_type" perm="product_type.get_all" admin />
        <ItemMenu icon={<RoomServiceIcon />} name="Recursos" path="/admin/product/service" perm="product_service.get_all" admin />
      </ListItemMenu>
      <ListItemMenu icon={<PersonIcon />} name={"Acessos"}>
        <ItemMenu icon={<GroupIcon />} name="Cargos" path="/admin/access/role" perm="role_client.get_all" admin />
        <ItemMenu icon={<PersonIcon />} name="Usuários" path="/admin/access/user" perm="user_client.get_all" admin/>
      </ListItemMenu>
      <ListItemMenu icon={<SettingsIcon />} name={"Config"} 
      >
        <ItemMenu icon={<GroupIcon />} name="Cargos" path="/admin/user/role" perm="role.get_all"  admin={true}/>
        <ItemMenu icon={<PersonIcon />} name="Usuários" path="/admin/user" perm="user.get_all" admin={true} />
        <ItemMenu icon={<Profile/>} perm={false} admin={true} />
        </ListItemMenu>
      <ItemMenu icon={<PersonIcon />} name="Perfil" path="/admin/access/user" admin/>
    </List>
  )
};

export const secondaryListItems = (
  <React.Fragment>

  </React.Fragment>
);
